import { Component, input, output } from '@angular/core';

import { SearchTab } from 'app/search/search-group.enum';

@Component({
    selector: 'app-search-dialog-tabs',
    templateUrl: './search-dialog-tabs.component.html',
})
export class SearchDialogTabsComponent {
    public activeTab = input<SearchTab>();

    public selectTab = output<SearchTab>();

    public SearchTab = SearchTab;
}
